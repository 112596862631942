<template>
  <form id="login-form" class="form" @submit.prevent="handleSubmit">
    <div class="log_main">
      <div class="bindPhine_title">
        <p>请输入帐号</p>
      </div>
      <div class="log_ui logTool">
        <div class="dsib log_tool_outer vm">
          <input
            class="txt username vm"
            type="text"
            id="userNameInp"
            placeholder="输入帐号"
            :value="account"
            @input="updateStore('account', $event.target.value)"
            tabindex="1"
          />
        </div>
      </div>
      <div class="append_op dsib">
        <div class="dsib autoLogSet" id="err">{{ errmsg }}</div>
      </div>
      <div class="logBtnOuter">
        <BtnWithLoading type="submit" class="btn">下一步</BtnWithLoading>
      </div>
    </div>
  </form>
</template>
<script>
import mapState from '@/mixins/map-state'
import updateStore from '@/mixins/update-store'
import handleErr from '@/mixins/handle-err'

export default {
  mixins: [mapState, updateStore, handleErr],
  data() {
    return {
      isPending: false
    }
  },
  methods: {
    handleSubmit() {
      let payload = {
        _csrf: this.csrf,
        challenge: this.challenge,
        LoginType: this.loginType
      }

      payload['account_name'] =
        this.account && this.encrypter.encrypt(this.account)

      this.isPending = true
      this.$api
        .findpwd(payload)
        .then(res => {
          this.isPending = false
          console.log(res)
          res.data = res.data || {}
          if (res.data.errcode !== '0' && res.data.errmsg) {
            this.updateStore('errmsg', res.data.errmsg)
          }

          if (res.data.errcode === '0') {
            this.updateStore('appId', res.data.data.AppId)
            this.updateStore('areaCode', res.data.data.AreaCode)
            this.updateStore('phoneMask', res.data.data.PhoneMask)
            this.updateStore('phoneCipher', res.data.data.PhoneCipher)
            this.updateStore('errmsg', '')

            this.$router.push('/verifyphone')
          }
        })
        .catch(err => {
          this.handleErr(err)
        })
    }
  }
}
</script>
